import './index.css'
import BlogCardImageBanner from '../BlogCardImageBanner'
import BlogCardDate from '../BlogCardDate'
import BlogCardTitle from '../BlogCardTitle'
import { useNavigate } from 'react-router-dom';


const BlogCard = (props) => {
    const navigate = useNavigate();

    return (
        <div className="blogCardContainer" onClick={() => navigate("/post")}>
            <div className='blogCardTop'>
                <BlogCardImageBanner image={props.image} alt={props.alt}/>
            </div>
            <div className='blogCardBottom'>
                <BlogCardDate date={props.date} />
                <BlogCardTitle title={props.title} />
            </div>
        </div>
    );
};

export default BlogCard;