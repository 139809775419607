import React from 'react';
import './index.css';

        
const ExamCardPreparationTitle = () => {
    return (
        <div className='examCardPreparationTitleContainer'>
             <h1 className='examCardPreparationTitle'>Preparo</h1>
        </div>
    );
};
export default ExamCardPreparationTitle;