import React from 'react';
import './index.css';
import Institucional from '../Institutional';
import Services from '../Services';
import JoinTheTeam from '../JoinTheTeam';
import ContactUs from '../ContactUs';
import Copyright from '../Copyright';


const Footer = () => {
    return (
        <div className="footerContainer" id='FaleConosco'>
            <div className='footerNavigationContainer'>
                <div className="footeLeftSideContainer">
                    <ContactUs />
                </div>
                <div className="footerRightSideContainer">
                    <Institucional />
                    <Services />
                    <JoinTheTeam />
                </div>
            </div>
            <Copyright />
            <img src={require('../../assets/images/WhatsApp FAB.png')} alt="whatsAppIcon" className="whatsAppIcon" />
        </div>
    );
};
export default Footer;