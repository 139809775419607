import './index.css'
        

const BlogCardDate = (props) => {
    return (
        <div className="blogCardDateContainer">
             <h1 className='blogCardDateH1'>{props.date}</h1>
        </div>
    );
};
export default BlogCardDate;