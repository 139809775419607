import React from 'react';
import './index.css';

        
const ExamCardPreparationDescription = (props) => {
    return (
        <div className='examCardPreparationDescriptionContainer'>
             <h1 className='examCardPreparationDescription'>{props.description}</h1>
        </div>
    );
};
export default ExamCardPreparationDescription;