import React from 'react';
import './index.css';

const HeroFigures = () => {
    return (
        <div className='heroFiguresContainer'>
            <div className='roundedImagesContainer'>
                <img src="./6b7b159b4ca12862e143244d9e6819d7.png" alt="Cintmed_Circle_Img" className="roundedImage"/>
            </div>
            <div className="squareImagesContainer">
                <div className="leftSquareImages">
                    <div className='roundedSquareFromLeftSide' />
                    <img
                        className='roundedImgFromLeftSide'
                        src="./9a743c4b16da30bdee88e1a2d8965890.png"
                        alt="Cintmed_Square_Img"
                    />
                </div>
                <div className="rightSquareImages">
                    <div className='roundedImgFromRightSide' />
                    <div className='squaredImgFromRightSide' />
                </div>
            </div>
        </div>
    );
};
export default HeroFigures;