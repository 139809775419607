import React, { useEffect } from 'react';
import './index.css';
import NavBar from '../../components/NavBar';
import BgImage from '../../components/BgImage';
import Footer from '../../components/Footer';
import BlogTitle from '../../components/BlogTitle';
import BlogCard from '../../components/BlogCard';
import BlogPageNavBar from '../../components/BlogPageNavBar';
import { animateScroll as scroll } from 'react-scroll';


const BlogPage = () => {
        
    useEffect(() => {
        scroll.scrollToTop(); // Rola suavemente para o topo da página ao carregar a página
      }, []);

    return (
        <div>
            <BgImage />
            <NavBar />
            <BlogTitle title="Blog Cintmed" />
            <div className='blog-page-grid-container'>
                {Array.from({ length: 10 }, (_, i) => (
                    <BlogCard key={i} image='./Pic1.png' alt='key' date='10 jan 2024' title='10 razões para praticar exercícios físicos' />
                ))}
            </div>
            <BlogPageNavBar />
            <Footer />
        </div>
    );
};
export default BlogPage;