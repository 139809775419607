import React from "react";
import './index.css'
import { ReactComponent as CheckIcon } from '../../assets/svg/Check.svg'
import { ReactComponent as CloseButton } from '../../assets/svg/CloseButton.svg'


const SuccessfullyModal = ({ Open, setOpen, name }) => {
    const handleClose = () => {
      setOpen(false);
    };
  
    if (Open === true) {
      return (
        <div className="successfullyModalBackground">
          <div className="successfullyModalContainer">
            <CloseButton className="CloseButton" onClick={handleClose} />
            <CheckIcon className="CheckIcon" />
            <h1 className="successfullyModalH1">{`Obrigado pela escolha, ${name}`}</h1>
            <p className="successfullyModalP">
              Recebemos suas informações e em breve vamos entrar em contato para
              confirmar seu agendamento.
            </p>
          </div>
        </div>
      );
    } else {
      return <></>;
    }
  };
export default SuccessfullyModal;