import {BrowserRouter, Routes, Route} from 'react-router-dom';
import HomePage from './pages/Home';
import BlogPage from './pages/Blog';
import PostPage from './pages/Post';

function AppRoutes() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/home" element={<HomePage />} />
                <Route path="/blog" element={<BlogPage />} />
                <Route path="/:post" element={<PostPage />} />
            </Routes>
        </BrowserRouter>
    );
}

export default AppRoutes;