import React from 'react';
import './index.css';



const Copyright = () => {
    return (
        <div className="copyrightContainer">
            <p className='copyrightLabel'>© Copyright 2024. Cintmed  Medicina Nuclear. Responsável Técnico: Dr. Fábio Caligaris – CRM-93135. <br/>Desenvolvimento Nativo <strong className='copyrightStrong'>Feeling Digital</strong></p>
        </div>
    );
};
export default Copyright;