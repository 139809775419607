import React from "react";
import './index.css';

        
const PostTitle = (props) => {
    return (
        <div className="postTitleContainer">
             <h1 className="postTitle">{props.title}</h1>
        </div>
    );
};
export default PostTitle;