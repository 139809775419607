import React from 'react';
import './index.css';

        
const FooterTitle = (props) => {
    return (
        <div className="footerTitleContainer">
            <h1 className="footerTitle">{props.title}</h1>
        </div>
    );
};
export default FooterTitle;