import React from "react";
import "./index.css";
        
const AboutDescription = () => {
    return (
        <div className="aboutDescriptionContainer">
             <label className="aboutDescriptionLabel">Localizada em uma região privilegiada de Ribeirão Preto, a CINTMED oferece atendimento humanizado, com dedicação especial para todos os seus usuários.
Seu corpo clínico é composto por profissionais especializados, com grande formação acadêmica e presença constante nos principais cursos, eventos e palestras da área da saúde para aumentar seu conhecimento e aperfeiçoar seu atendimento.</label>
        </div>
    );
};
export default AboutDescription;