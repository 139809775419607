import React, { useEffect } from 'react';
import './index.css';
import NavBar from '../../components/NavBar';
import BgImage from '../../components/BgImage';
import Blog from '../../components/Blog';
import Footer from '../../components/Footer';
import PostTitle from '../../components/PostTitle';
import PostDate from '../../components/PostDate';
import PostImageBanner from '../../components/PostImageBanner';
import PostDescription from '../../components/PostDescription';
import PostVideo from '../../components/PostVideo';
import { animateScroll as scroll } from 'react-scroll';


const PostPage = () => {
        
    useEffect(() => {
        scroll.scrollToTop(); // Rola suavemente para o topo da página ao carregar a página
      }, []);

    return (
        <div className='postContainer'>
            <BgImage />
            <NavBar />
            <PostDate date="10 Jan 2024" />
            <PostTitle title="10 razões para praticar exercícios físicos" />
            <PostImageBanner image="./PostBannerImage.png" alt="imagem de uma mulher correndo" />
            <PostDescription description="Ac quis vestibulum magna est consequat suspendisse tristique hac commodo parturient ac tincidunt senectus aliquam a pulvinar leo quis natoque a vel parturient libero. Turpis sociosqu fringilla torquent a nostra neque dui scelerisque molestie suspendisse erat justo fames parturient interdum dui vestibulum non a felis ullamcorper lectus dapibus. Mi suspendisse suspendisse libero egestas massa lacinia potenti vestibulum potenti per vulputate ultricies suspendisse a a convallis parturient quis. 
    

Consequat phasellus urna posuere suspendisse conubia accumsan at phasellus viverra lorem a conubia per a vestibulum nunc non consectetur odio vestibulum a a parturient in quis. Penatibus vitae a parturient consectetur ultrices pulvinar porttitor at a pretium accumsan himenaeos tincidunt ante a fringilla a eros sit dui suspendisse congue orci bibendum semper lorem a. Scelerisque mattis vulputate a diam a id habitasse auctor consectetur sit a mus accumsan parturient tristique eu. " />
            <PostVideo video="https://www.youtube.com/embed/3JZ_D3ELwOQ" poster="./VideoImage.png"/>
            <PostDescription description="Ac quis vestibulum magna est consequat suspendisse tristique hac commodo parturient ac tincidunt senectus aliquam a pulvinar leo quis natoque a vel parturient libero. Turpis sociosqu fringilla torquent a nostra neque dui scelerisque molestie suspendisse erat justo fames parturient interdum dui vestibulum non a felis ullamcorper lectus dapibus. Mi suspendisse suspendisse libero egestas massa lacinia potenti vestibulum potenti per vulputate ultricies suspendisse a a convallis parturient quis. 
    

    Consequat phasellus urna posuere suspendisse conubia accumsan at phasellus viverra lorem a conubia per a vestibulum nunc non consectetur odio vestibulum a a parturient in quis. Penatibus vitae a parturient consectetur ultrices pulvinar porttitor at a pretium accumsan himenaeos tincidunt ante a fringilla a eros sit dui suspendisse congue orci bibendum semper lorem a. Scelerisque mattis vulputate a diam a id habitasse auctor consectetur sit a mus accumsan parturient tristique eu. " />
            <Blog />
            <Footer />
        </div>
    );
};
export default PostPage;