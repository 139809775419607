import React from 'react';
import './index.css';
import ExamConsultationLabel from '../ExamConsultationLabel';
import ExamConsultationButton from '../ExamConsultationButton';


const ExamConsultation = () => {
    return (
        <div className='examConsultationContainer' id='ResultadosExames'>
            <ExamConsultationLabel />
            <ExamConsultationButton title="VER RESULTADOS DE EXAMES" />
        </div>
    );
};
export default ExamConsultation;