import React from 'react';
import './index.css';

        
const ExamCardImageBanner = (props) => {
    return (
        <div className="examCardImageBannerContainer">
             <img key={props.key} src={props.cardImage} alt="Imagem de exame de sangue" className="examCardImageBanner"/>
        </div>
    );
};
export default ExamCardImageBanner;