import React from 'react';
import './index.css';

const HeroDescription = () => {
    return (
        <div>
            <label className="heroDescription">A CINTMED realiza exames cintilográficos com tecnologia para você de Ribeirão Preto e região.</label>
        </div>
    );
};
export default HeroDescription;