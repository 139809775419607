import React from "react";
import "./index.css";
import {ReactComponent as SearchIcon} from "../../assets/svg/SearchIcon.svg";

const SearchInput = ({ value, onChange, onSearch }) => {
  return (
    <div className="search-container">
      <input
        type="text"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder="Busque"
        className="search-input"
      />
      <SearchIcon onClick={onSearch} className="search-button"/>
    </div>
  );
};

export default SearchInput;
