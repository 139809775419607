import React from 'react';
import ExamCardImageBanner from '../ExamCardImageBanner';
import ExamCardTitle from '../ExamCardTitle';
import ExamCardDescription from '../ExamCardDescription';
import ExamCardPreparationTitle from '../ExamCardPreparationTitle';
import ExamCardPreparationDescription from '../ExamCardPreparationDescription';
import ExamCardButton from '../ExamCardButton';


import './index.css';


const ExamCard = (props) => {
    return (
        <div className="examCardContainer">
            <div className="examCardHeader">
                <ExamCardImageBanner cardImage={props.cardImage} key={props.key}/>
            </div>
            <div className="examCardBottom">
                <ExamCardTitle title="Avaliação de Viabilidade Miocárdica" />
                <ExamCardDescription description="Ad mi interdum nulla morbi a donec eros elementum tellus commodo a vestibulum bibendum dui vel fusce natoque gravida tempor." />
                <ExamCardPreparationTitle />
                <ExamCardPreparationDescription description="Não há necessidade de preparo prévio." />
                <ExamCardButton text="Agendar" />
            </div>
        </div>
    );
};
export default ExamCard;