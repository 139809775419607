import React from 'react';
import './index.css';
import {ReactComponent as FacebookIcon } from '../../assets/svg/Facebook.svg';
import {ReactComponent as InstagramIcon } from '../../assets/svg/Instagram.svg';


const ContactUsSocialMedia = () => {
    return (
        <div className="ContactUsSocialMediaContainer">
            <FacebookIcon className='facebookIcon'/>
            <InstagramIcon className='instagramIcon'/>
            {/* <img src={require('../../assets/images/Facebook.png')} alt="facebookIcon" className="facebookIcon" />
            <img src={require('../../assets/images/Instagram.png')} alt="instagramIcon" className="instagramIcon" /> */}
        </div>
    );
};
export default ContactUsSocialMedia;