import './index.css'
        

const BlogCardTitle = (props) => {
    return (
        <div className="blogCardTitleContainer">
             <h1 className='blogCardTitleH1'>{props.title}</h1>
        </div>
    );
};
export default BlogCardTitle;