import React from 'react';
import './index.css';
import HeroTitle from '../HeroTitle';
import HeroDescription from '../HeroDescription';

const HeroContents = () => {
    return (
        <div className="heroContentsContainer">
            <HeroTitle />
            <HeroDescription />
        </div>
    );
};
export default HeroContents;