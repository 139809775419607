import React, { useState, useRef, useEffect } from "react";
import './index.css'
import { motion } from "framer-motion";
import ExamCard from "../ExamCard";
import ExamForm from "../ExamForm";
import ExamTitle from "../ExamTitle";


const Exams = () => {
    const carousel = useRef(0);
    const [width, setWidth] = useState(0);

    useEffect(() => {
        setWidth(carousel.current.scrollWidth - carousel.current?.offsetWidth)
    }, [carousel.current?.scrollWidht]);

    return (
        <div className="examsContainer" id="Exames">
            <div className="examsHeader">
                <ExamTitle />
                <ExamForm />
            </div>
            <motion.div className="examsFooter">
                <motion.div className="examsContent"
                    ref={carousel}
                    initial={{ x: -1000 }}
                    animate={{ x: 0 }}
                    transition={{ duration: 2 }}
                    whileTap={{ cursor: "grabbing" }}
                    drag="x"
                    dragConstraints={{ left: -width, right: 0 }}
                >  
                    {Array.from({ length: 6 }, (_, i) => (
                            <ExamCard key={i} cardImage={'./Pic.png'} />
                        ))}
                </motion.div>
            </motion.div>

        </div>
    );
}

export default Exams;