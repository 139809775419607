import React, { useState } from "react";
import './index.css';
import SuccessfullyModal from '../SuccessfullyModal';
import { ReactComponent as CloseButton } from '../../assets/svg/CloseButton.svg'
import { ReactComponent as PdfIcon } from '../../assets/svg/pdfIcon.svg'


const Modal = ({ isOpen, setIsOpen, modalImage, title, description, preparation }) => {
    const [isMyModalOpen, setIsMyModalOpen] = useState(false);

    const handleOpenStatusModal = () => {
            setIsMyModalOpen(true);
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    if (isOpen === true) {
        return (
            <div className="modalBackground">
                <div className="modalContainer">
                    <div className="modalExamInformation">
                        <CloseButton className="CloseButton" onClick={handleClose} />
                        <img src={modalImage} alt="Imagem do exame" key="modal" className="modalImage" />
                        <div className="modalinformationContainer">
                            <div className="modalTitleContainer">
                                <h1 className="modalH1">{title}</h1>
                                <p className="modalP">{description}</p>
                            </div>
                            <div className="modalPreparationTitleContainer">
                                <h2 className="modalPreparationH2">PREPARO</h2>
                                <p className="modalP">{preparation}</p>
                                <div className="modalButtonContainer">
                                    <PdfIcon className="PdfIcon" />
                                    <label className="modalButton">BAIXAR PDF</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <form className="formContainer">
                        <h1 className="formModalH1">Agende</h1>
                        <div className="inputArea">
                            <input type="text" placeholder="Nome Completo" className="inputField" />
                        </div>
                        <div className="inputArea">
                            <input type="text" placeholder="E-mail" className="inputField" />
                        </div>
                        <div className="inputArea">
                            <input type="text" placeholder="Telefone" className="inputField" />
                        </div>
                        <div className="inputArea">
                            <input type="text" placeholder="Convênio" className="inputField" />
                        </div>
                        <div className="inputObsArea">
                            <textarea placeholder="Observações" className="inputField" rows="10"/>
                        </div>
                        <button type="button" onClick={handleOpenStatusModal} className="formButton">ENVIAR</button>
                    </form>
                    <SuccessfullyModal Open={isMyModalOpen} setOpen={setIsMyModalOpen} name="Usuário" />
                </div>
            </div>
        );
    } else {
        return <></>;
    }
};
export default Modal;