import React from "react";
import "./index.css";
import AboutFigures from "../AboutFigures";
import AboutContents from "../AboutContents";
import HeroButton from "../HeroButton";


const About = () => {
    return (
        <div className="about-container" id="Sobre">
            <AboutFigures />
            <AboutContents />
            <HeroButton />
        </div>
    );
};
export default About;