import React from "react";
import "./index.css";

const AboutTitle = () => {
    return (
        <div>
            <label className="aboutTitleLabel">Atendê-lo bem é a nossa maior conquista.</label>
        </div>
    );
};
export default AboutTitle;