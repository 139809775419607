import React from 'react';
import './index.css';


const NavButton = (props) => {
    return (
        <div className='homeNavButtonContainer'>
            <a href={props.section} className="homeNavButton">{props.text.toUpperCase()}</a>
            <div className="homeNavButtonLine"></div>
        </div>
    );
};
export default NavButton;