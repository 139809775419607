import React, { useState } from 'react';
import Modal from '../Modal';
import './index.css'


const ExamCardButton = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  return (
    <div className="examButtonContainer">
      <h1 onClick={handleOpenModal} className='examButtonH1'>{props.text}</h1>
      <Modal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        modalImage="./examModalImg.png"
        title="Cintilografia com Análogo de Somatotastina"
        description="Scelerisque neque purus a a cras condimentum consectetur mi elementum hendrerit vestibulum morbi a sapien rutrum fringilla condimentum pretium at mi vestibulum a sociis a senectus. Parturient parturient conubia morbi nunc parturient scelerisque tempor velit metus a condimentum fermentum suspendisse sem mi ullamcorper eu sociosqu ."
        preparation="Descontinuar terapia com octreotide, 24 horas antes do exame, a critério clínico. Hidratação. Uso de laxante para melhor avaliação da região abdominal."
      />
    </div>
  );
};
export default ExamCardButton;