import React from 'react';
import './index.css'
        

const PostDate = (props) => {
    return (
        <div className="postDateContainer">
             <h1 className='postDateH1'>{props.date}</h1>
        </div>
    );
};
export default PostDate;