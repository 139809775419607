import React from "react";
import "./index.css";

const AboutBrands = () => {
    return (
        <div className="aboutBrandsContainer">
            <img src="./Particular.png" alt="Particular" />
            <img src="./Unimed.png" alt="Unimed" />
            <img src="./Bradesco.png" alt="Bradesco" />
            <img src="./SulAmerica.png" alt="SulAmerica" />
            <img src="./hapVida.png" alt="hapVida" />
        </div>
    );
};
export default AboutBrands;