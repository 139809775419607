import React from 'react';
import './index.css';
import { useNavigate } from 'react-router-dom';
        

const BlogButton = (props) => {
    const navigate = useNavigate();

    return (
        <div className="blogButtonContainer" onClick={() => navigate("/blog")}>
             <h1 className='blogButtonH1'>{props.text}</h1>
        </div>
    );
};
export default BlogButton;