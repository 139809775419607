import React from "react";
import "./index.css";

const ExamDropDownInput = ({ options, value, onChange, defaultOpition }) => {
  return (
    <div className="examDropDownInputContainer">
      <select value={value} onChange={(e) => onChange(e.target.value)} className="select-input">
      <option value="" disabled selected className="optionLabel">{defaultOpition}</option>
        {options.map((option) => (
          <option key={option.value} value={option.value} className="optionLabel">{option.label}</option>
        ))}
      </select>
    </div>
  );
};

export default ExamDropDownInput;
