import React from 'react';
import './index.css';


const ExamCardTitle = (props) => {
    return (
        <div className='examCardTitleContainer'>
             <h1 className='examCardTitle'>{props.title}</h1>
        </div>
    );
};
export default ExamCardTitle;