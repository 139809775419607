import React from 'react';
import './index.css';

const NavBarLogo = () => {
    return (
        <div>
            <img 
                src="./cintmed_logo.png"
                alt="cintmed_logo"
                className="navBarLogo" 
            />
        </div>
    );
};
export default NavBarLogo;