import React from 'react';
import './index.css';
import ContactUsSocialMedia from '../ContactUsSocialMedia';
import ContactUsWhatsApp from '../ContactUsWhatsApp';
import ContactUsPhone from '../ContactUsPhone';
import ContactUsAddress from '../ContactUsAddress';
import FooterTitle from '../FooterTitle';


const ContactUs = () => {
    return (
        <div className="ContactUsContainer">
            <FooterTitle title="FALE CONOSCO" />
            <ContactUsAddress />
            <ContactUsPhone />
            <ContactUsWhatsApp />
            <ContactUsSocialMedia />
        </div>
    );
};
export default ContactUs;