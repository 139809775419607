import React from 'react';
import './index.css';
import NavBarLogo from '../NavBarLogo';
import NavButton from '../NavButton';
import ExamConsultationButton from '../ExamConsultationButton';
import { useNavigate } from 'react-router-dom';


const NavBar = () => {
    const navigate = useNavigate();
    return (
        <div className="navBarContainer">
            <div className="navBarLogoContainer" onClick={() => navigate('/')}>
                <NavBarLogo />
            </div>
            <div className="navBarButtonsContainer">
                <NavButton text="Sobre" section="#Sobre" />
                <NavButton text="Exames" section="#Exames" />
                <NavButton text="Blog" section="#Blog" />
                <NavButton text="Fale Conosco" section="#FaleConosco" />
                <ExamConsultationButton title="RESULTADOS DE EXAMES"/>
                {/* <NavButton text="Resultados de Exames" section="#ResultadosExames" /> */}
            </div>
        </div>
    );
};
export default NavBar;