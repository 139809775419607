import React from 'react';
import './index.css';


const ExamConsultationLabel = () => {
    return (
        <div className='examConsultationLabelContainer'>
            <p className="examConsultationLabel-normal">Acesse seus <strong className="examConsultationLabel-strong">exames on-line</strong> de forma rápida e fácil.</p>
        </div>
    );
};
export default ExamConsultationLabel;