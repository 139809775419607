import React from 'react';
import './index.css';

const HeroTitle = () => {
    return (
        <div>
            <label className="heroTitle">Medicina Nuclear a favor do seu bem-estar</label>
        </div>
    );
};
export default HeroTitle;