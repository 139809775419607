import React, { useState } from "react";
import "./index.css";

const PaginationImages = () => {
  const totalPages = 10; // Alterei o número total de páginas para demonstração
//   const imagesPerPage = 3; // Alterei o número de imagens por página para demonstração

  const [currentPage, setCurrentPage] = useState(1);

  const nextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const prevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const goToPage = (page) => {
    setCurrentPage(page);
  };

  const pageNumbers = [];
  for (let i = Math.max(1, currentPage - 2); i <= Math.min(totalPages, currentPage + 1); i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="pagination-container">
      <div className="image-grid">
        {/* Renderizar imagens para a página atual */}
      </div>
      <div className="pagination-controls">
        <button onClick={prevPage} disabled={currentPage === 1} className="disabledPage">{"<"}</button>
        {pageNumbers.map((pageNumber) => (
          <button key={pageNumber} onClick={() => goToPage(pageNumber)} className={currentPage === pageNumber ? "activePage" : "disabledPage"}>
            {pageNumber}
          </button>
        ))}
        <button onClick={nextPage} disabled={currentPage === totalPages} className="disabledPage">{">"}</button>
      </div>
    </div>
  );
};

export default PaginationImages;
