import React from 'react';
import './index.css';
import { useNavigate } from 'react-router-dom';

        
const FooterButton = (props) => {
    const navigate = useNavigate();
    return (
        <div className="footerButtonContainer" onClick={() => props.title === "Home"?  navigate('/'): ""}>
            <a  href={props.section} className="footerButton">{props.title}</a>
        </div>
    );
};
export default FooterButton;