import React from 'react';
import './index.css';
import FooterTitle from '../FooterTitle';
import FooterButton from '../FooterButton';


const Services = () => {
    return (
        <div className="servicesContainer">
            <FooterTitle title="SERVIÇOS" />
            <FooterButton title="Exames e Preparos" section="#Exames"/>
            <FooterButton title="Resultados de exames" section="#ResultadosExames"/>
        </div>
    );
};
export default Services;