import React from 'react';
import './index.css';


const ExamConsultationButton = (props) => {
    return (
        <div className='exam-consultation-button-area' onClick={() => window.location.href="http://189.69.242.213:3390/csadvweb/Patient/Login/Index"}>
            <label type="button" className='exam-consultation-button'>{props.title}</label>
        </div>
    );
};
export default ExamConsultationButton;