import React from 'react';
import './index.css';
import HeroContents from '../HeroContents';
import HeroFigures from '../HeroFigures';


const Hero = () => {
    return (
        <div className="hero-container">
            <HeroContents />
            <HeroFigures />

        </div>
    );
};
export default Hero;