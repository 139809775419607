import React from "react";
import "./index.css";

const AboutSubTitle = () => {
    return (
        <div className="aboutSubTitleContainer">
            <label className="aboutSubTitleLabel">Atendimento do seu jeito</label>
        </div>
    );
};
export default AboutSubTitle;