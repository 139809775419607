import React from 'react';
import './index.css';
import FooterTitle from '../FooterTitle';
import FooterButton from '../FooterButton';


const Institucional = () => {

    return (
        <div className="institucionalContainer">
            <FooterTitle title="INSTITUCIONAL" />
            <FooterButton title="Home" section="#Home"/>
            <FooterButton title="Sobre" section="#Sobre"/>
            <FooterButton title="Estrutura Física" section="#Sobre" />
            <FooterButton title="Blog" section="#Blog"/>
            <FooterButton title="LGPD" />
        </div>
    );
};
export default Institucional;