import React from 'react'
import './index.css'
import BlogCard from '../BlogCard'
import BlogTitleHome from '../BlogTitleHome'
import BlogButton from '../BlogButton'
import { useNavigate } from 'react-router-dom';

const objects = [
    {
        image: './Pic.png',
        alt: 'blogImage',
        date: '10 Jan 2024',
        title: 'Tecnologia ajuda a identificar pensamentos suicidas'
    },
    {
        image: './Pic1.png',
        alt: 'blogImage',
        date: '10 Jan 2024',
        title: '10 razões para praticar exercícios físicos'
    },
    {
        image: './Pic2.png',
        alt: 'blogImage',
        date: '10 Jan 2024',
        title: 'Divisão de saúde da Alphabet anuncia smartwatch para estudar doenças'
    }
]

const Blog = () => {
    const navigate = useNavigate();
    return (
        <div className="blogContainer" id='Blog'>
            <div className='blogTop'>
                <BlogTitleHome title="Blog" />
                <BlogButton text="Ver tudo"/>
            </div>
            <div className='blogBottom' onClick={() => navigate('/post')}>
                {objects.map((object, index) => {
                    return (
                        <BlogCard
                            key={index}
                            image={object.image}
                            alt={object.alt}
                            date={object.date}
                            title={object.title}
                        />
                    )
                })}
                {/* <BlogCard /> */}
            </div>
        </div>
    );
};

export default Blog;