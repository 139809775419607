import React from 'react';
import './index.css';

        
const ExamCardDescription = (props) => {
    return (
        <div className='examCardDescriptionContainer'>
             <h1 className='examCardDescription'>{props.description}</h1>
        </div>
    );
};
export default ExamCardDescription;