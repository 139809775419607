import React from 'react';
import './index.css';
import { ReactComponent as Place } from '../../assets/svg/place.svg';


const ContactUsAddress = () => {
    return (
        <div className="contactUsAddressContainer">
            <Place />
            <label className="contactUsAddressLabel">Rua Cesário Mota, 555 Jd. Paulista – 14090-052 Ribeirão Preto/SP</label>
        </div>
    );
};
export default ContactUsAddress;