import React from 'react';
import './index.css';
import { ReactComponent as Phone } from '../../assets/svg/local_phone.svg';


const ContactUsPhone = () => {
    return (
        <div className="contactUsPhoneContainer">
            <Phone />
            <label className="contactUsPhoneLabel">16 3421.4656</label>
        </div>
    );
};
export default ContactUsPhone;