import React, { useEffect } from 'react';
import './index.css';
import NavBar from '../../components/NavBar';
import BgImage from '../../components/BgImage';
import Hero from '../../components/Hero';
import ExamConsultation from '../../components/ExamsConsultation';
import About from '../../components/About';
import Blog from '../../components/Blog';
import Footer from '../../components/Footer';
import Exams from '../../components/Exams';
import { animateScroll as scroll } from 'react-scroll';


const HomePage = () => {
    
    useEffect(() => {
        scroll.scrollToTop(); // Rola suavemente para o topo da página ao carregar a página
      }, []);

    return (
        <div>
            <BgImage />
            <NavBar />
            <Hero />
            <ExamConsultation />
            <About />
            <Exams />
            <Blog />
            <Footer />
        </div>
    );
};
export default HomePage;