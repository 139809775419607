import React from "react";
import "./index.css";


const PostImageBanner = (props) => {
    return (
        <div className="postImageBannerContainer">
            <img src={props.image} alt={props.alt} className="postImageBannerImg"/>
        </div>
    );
};
export default PostImageBanner;