
import React from "react";
import "./index.css";

        
const BgImage = () => {
    return (
        <div className="bgImageContainer">
        </div>
    );
};
export default BgImage;