import React from "react";
import "./index.css";

const PostDescription = (props) => {
    return (
        <div className="postDescriptionContainer">
            <p className="postDescriptionText">{props.description}</p>
        </div>
    );
};

export default PostDescription;