import React from "react";
import "./index.css";


const PostVideo = (props) => {
    return (
        <div className="videoContainer">
            <video src={props.video} poster={props.poster}></video>
        </div>
    );
};

export default PostVideo;