import React from 'react';
import './index.css';
import { ReactComponent as WhatsApp } from '../../assets/svg/WhatsApp.svg';


const ContactUsWhatsApp = () => {
    return (
        <div className="contactUsWhatsAppContainer">
            <WhatsApp />
            <label className="contactUsWhatsAppLabel">16 3421.4656</label>
        </div>
    );
};
export default ContactUsWhatsApp;