import React from "react";
import "./index.css";
import AboutTitle from "../AboutTitle";
import AboutDescription from "../AboutDescription";
import AboutSubTitle from "../AboutSubTitle";
import AboutBrands from "../AboutBrands";


const AboutContents = () => {
    return (
        <div className="aboutContentsContainer">
            <AboutTitle />
            <AboutDescription />
            <AboutSubTitle />
            <AboutBrands />
        </div>
    );
};
export default AboutContents;