import React, { useState } from "react";
import SearchInput from "../ExamSearchInput";
import ExamDropDownInput from "../ExamDropDownInput";
import "./index.css";

const ExamForm = () => {
  const options = [
    { value: "option1", label: "Opção 1" },
    { value: "option2", label: "Opção 2" },
    { value: "option3", label: "Opção 3" },
  ];

  const [selectedOption, setSelectedOption] = useState("");

  const handleSelectChange = (value) => {
    setSelectedOption(value);
  };

  const [searchValue, setSearchValue] = useState("");

  const handleInputChange = (value) => {
    setSearchValue(value);
  };

  const handleSearch = () => {
    console.log("Searching for:", searchValue);
    // Coloque aqui a lógica para realizar a busca
  };

  return (
    <div className="examFormContainer">
      <SearchInput
        value={searchValue}
        onChange={handleInputChange}
        onSearch={handleSearch}
      />
      <ExamDropDownInput
        options={options}
        defaultOpition="Especialidade"
        value={selectedOption}
        onChange={handleSelectChange} />

      <ExamDropDownInput
        options={options}
        defaultOpition="Tipo"
        value={selectedOption}
        onChange={handleSelectChange} />
    </div>
  );
};

export default ExamForm;
