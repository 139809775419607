import './index.css'
        

const BlogCardImageBanner = (props) => {
    return (
        <div className="blogCardImageBannerContainer">
             <img src={props.image} alt={props.alt} className='blogCardImageBannerH1'></img>
        </div>
    );
};

export default BlogCardImageBanner;