import React from 'react';
import './index.css';
import FooterTitle from '../FooterTitle';
import FooterButton from '../FooterButton';


const JoinTheTeam = () => {
    return (
        <div className="joinTheTeamContainer">
            <FooterTitle title="FAÇA PARTE DA EQUIPE" />
            <FooterButton title="Trabalhe conosco" />
        </div>
    );
};
export default JoinTheTeam;