import React from "react";
import "./index.css";

const AboutFigures = () => {
    return (
        <div className="aboutFiguresContainer">
            <div className="leftSideAboutFiguresContainer">
                <img src="./156bc1aa671a3e337913ce574715153a.png" alt="1" className="leftSideAboutImgTop" />
                <div className="leftSideAboutBottomContainer">
                    <img src="./deb646c37aea927f2cf55a45f6ff3d34.png" alt="2" className="leftSideAboutImgBottom" />
                    <img src="./b84b7eeb698270f5eae0b63012c3601a.png" alt="2" className="leftSideAboutImgBottom" />
                </div>
            </div>
            <div className="rightSideAboutFiguresContainer">
                <img src="./3f54fe537da0dda1fb715b02068a0169.png" alt="3" className="rightSideAboutImg" />
            </div>
        </div>
    );
};
export default AboutFigures;