import React from "react";
import './styles.css';


const HeroButton = () => {
    return (
        <div className="hero-button-area">
            <label className="hero-button">VER TODAS AS FOTOS</label>
        </div>
    );
}
export default HeroButton;